<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/Welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>支付结算</el-breadcrumb-item>
      <el-breadcrumb-item>建行日汇总</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card v-if="isShowData">
      <!-- 查询 -->
      <div
        style="
          margin-bottom: 20px;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
        "
      >
        <label style="margin: 10px" for=""
          >停车场：
          <el-select
            v-model="query.pkId"
            filterable
            placeholder="请选择"
            style="width: 150px"
            size="small"
          >
            <el-option
              v-for="item in PkInfoSelect"
              :key="item.id"
              :label="item.pkName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </label>
        <label style="margin: 10px" for=""
          >创建时间：<el-date-picker
            v-model="beginTime"
            type="datetime"
            value-format="timestamp"
            size="small"
            placeholder="选择日期时间"
          >
          </el-date-picker>
          至
          <el-date-picker
            v-model="endTime"
            type="datetime"
            value-format="timestamp"
            size="small"
            default-time="23:59:59"
            placeholder="选择日期时间"
            @change="inTimeEndchange"
          >
          </el-date-picker
        ></label>
        <el-button
          style="margin-left: 10px"
          type="primary"
          size="small"
          @click="orderDayTotalcx"
          >查询</el-button
        >
        <el-button size="small" @click="orderDayTotalcz()">重置</el-button>
      </div>
      <!-- 点击添加显示对话框 -->
      <el-button
        type="primary"
        size="small "
        icon="el-icon-plus"
        @click="exportOrder"
        >导出选择的明细记录</el-button
      >
      <el-button
        type="danger"
        size="small "
        icon="el-icon-share"
        @click="settleAccounts"
        >结算</el-button
      >
      <hr />
      <!-- 表格 -->
      <transition appear tag="div">
        <el-table
          v-loading="loading"
          ref="multipleTable"
          :data="tableData"
          stripe
          @selection-change="handleSelectionChange"
          tooltip-effect="dark"
          style="width: 100%"
        >
          <el-table-column
            header-align="left"
            align="left"
            type="selection"
            width="55"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="totalTime"
            label="日期"
          >
            <template slot-scope="scope">
              <span>{{ conversionTime1(scope.row.totalTime / 1000) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="totalCloudMoney"
            label="订单总金额"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="totalCcbMoney"
            label="可结算金额"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="accountStatus"
            label="结算状态"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.accountStatus == 1">未结算</span>
              <span v-if="scope.row.accountStatus == 2">已结算</span>
              <span v-if="scope.row.accountStatus == 3">结算异常</span>
            </template>
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            label="操作"
            width="120"
            fixed="right"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                @click="isShowQueryDialog(scope)"
                size="mini"
                icon="el-icon-share"
                >查看当日明细</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </transition>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="query.current"
        :page-sizes="[10, 50, 100]"
        :page-size="query.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <!-- 查看当日明细 -->
    <el-dialog
      title="订单详细"
      :visible.sync="isShowQuery"
      width="40%"
      v-el-drag-dialog
    >
      <el-table :data="tableDatatk" style="width: 100%">
        <el-table-column prop="payOrderNo" label="订单编号"></el-table-column>
        <el-table-column
          prop="orderPayMoney"
          label="订单金额"
        ></el-table-column>
        <el-table-column prop="payTimeDate" label="订单日期">
          <template slot-scope="scope">
            <span>{{ conversionTime(scope.row.payTimeDate / 1000) }}</span>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
  import {
    orderDayTotal,
    orderDetailDay,
    exportOrderCollectDay,
    settleAccounts,
  } from '@/api/common.js';
  import { findPkInfoSelect } from '@/api/Parkinglot.js';
  export default {
    data() {
      return {
        loading: false,
        isShowData: false,
        isShowQuery: false,
        tableData: [],
        tableDatatk: [],
        beginTime: undefined, // 起始
        endTime: undefined, // 截至
        query: {
          // 查询条件
          current: 1,
          size: 10,
          pkId: '', // 停车场id
          beginTime: undefined, // 起始
          endTime: undefined, // 截至
        },
        ids: undefined,
        total: undefined,
        PkInfoSelect: [], // 保存停车场下拉框
      };
    },
    created() {
      this.findPkInfoSelect();
    },
    methods: {
      orderDayTotal() {
        // 列表
        this.query.beginTime = this.beginTime;
        this.query.endTime = this.endTime;
        if (this.query.beginTime && !this.query.endTime) {
          return this.$message.warning('请输入截止时间');
        } else if (!this.query.beginTime && this.query.endTime) {
          return this.$message.warning('请输入开始时间');
        }
        if (this.query.beginTime || this.query.endTime) {
          this.query.beginTime = (this.query.beginTime / 1000).toFixed(0);
          this.query.endTime = (this.query.endTime / 1000).toFixed(0);
        }
        orderDayTotal(this.query).then((res) => {
          this.tableData = res.data.data.list;
          this.total = res.data.data.total;
          if (this.tableData) {
            this.isShowData = true;
            this.loading = false;
          }
        });
      },
      orderDayTotalcx() {
        // 查询
        this.query.current = 1;
        this.orderDayTotal();
      },
      orderDayTotalcz() {
        // 重置
        this.query.pkId = this.PkInfoSelect[0].id;
        this.beginTime = undefined;
        this.endTime = undefined;
        this.orderDayTotal();
      },
      findPkInfoSelect() {
        // 停车场下拉框
        findPkInfoSelect({ allRecord: 0 }).then((res) => {
          if (res.data.code === '200') {
            this.PkInfoSelect = res.data.data;
            this.query.pkId = this.PkInfoSelect[0].id;
            this.orderDayTotal();
          }
        });
      },
      handleSelectionChange(val) {
        this.ids = val.map((item) => {
          return item.id;
        });
      },
      isShowQueryDialog(scope) {
        // 查询当日订单
        const obj = {
          id: scope.row.id,
        };
        orderDetailDay(obj, { pk_id: this.query.pkId }).then((res) => {
          if (res.data.code === '200') {
            this.tableDatatk = res.data.data;
            this.isShowQuery = true;
          }
        });
      },
      handleSizeChange(val) {
        this.loading = true;
        this.query.size = val;
        this.orderDayTotal();
      },
      handleCurrentChange(val) {
        this.loading = true;
        this.query.current = val;
        this.orderDayTotal();
      },
      exportOrder() {
        // 导出
        if (this.ids) {
          exportOrderCollectDay(
            { ids: this.ids },
            { pk_id: this.query.pkId }
          ).then((res) => {
            if (res.data.code === '200') {
              window.open(res.data.data);
            }
          });
        } else {
          this.$message.warning('请勾选要导出的数据');
        }
      },
      settleAccounts() {
        // 结算
        if (this.ids) {
          settleAccounts({ ids: this.ids }, { pk_id: this.query.pkId }).then(
            (res) => {
              if (res.data.code === '200') {
                this.$message.success('结算成功');
              }
            }
          );
        } else {
          this.$message.warning('请勾选要结算的数据');
        }
      },
      // 结束时间选择
      inTimeEndchange() {
        if (this.beginTime === null || this.beginTime === undefined) {
          this.endTime = null;
          return this.$message.warning('请选择开始时间');
        } else if (this.endTime < this.beginTime) {
          this.endTime = this.beginTime + 3600 * 1000 * 24 * 1;
          return this.$message.warning('不可选择小于开始时间的日期');
        }
      },
    },
  };
</script>
<style lang="scss" scoped></style>
